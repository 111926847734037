import { createSlice } from "@reduxjs/toolkit";

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    recentCompanies: [],
    hotCompanies: [],
    categoryCompanies: [], // Existing state, unchanged
    companiesByCategory: {}, // New state to manage companies by industry ID
    allCompanies: [],
    industriesData: [],
    searchData: [],
    popularCompanies: [],
    companiesReels: [],
    recommendationsData: [],
    searchedCompaniesData: [], // New state for storing searched companies
  },
  reducers: {
    setRecentCompanies: (state, action) => {
      state.recentCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([
          ...state.recentCompanies,
          ...state.hotCompanies,
          ...state.categoryCompanies,
        ])
      );
    },
    setHotCompanies: (state, action) => {
      state.hotCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([
          ...state.recentCompanies,
          ...state.hotCompanies,
          ...state.categoryCompanies,
        ])
      );
    },
    setCategoryCompanies: (state, action) => {
      state.categoryCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([
          ...state.recentCompanies,
          ...state.hotCompanies,
          ...state.categoryCompanies,
        ])
      );
    },
    setIndustries: (state, action) => {
      state.industriesData = action.payload;
    },
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setPopularCompanies: (state, action) => {
      state.popularCompanies = action.payload;
      state.allCompanies = Array.from(
        new Set([...state.allCompanies, ...state.popularCompanies])
      );
    },
    setCompaniesReels: (state, action) => {
      state.companiesReels = action.payload;
    },
    addCompaniesByCategory: (state, action) => {
      const { industryId, companies } = action.payload;
      state.companiesByCategory[industryId] = companies;
      state.allCompanies = Array.from(
        new Set([...state.allCompanies, ...companies])
      );
    },
    setSearchedCompaniesData: (state, action) => {
      state.searchedCompaniesData = action.payload;
      // state.allCompanies = Array.from(
      //   new Set([
      //     ...state.recentCompanies,
      //     ...state.hotCompanies,
      //     ...state.categoryCompanies,
      //     ...state.popularCompanies,
      //     ...state.searchedCompaniesData, // Add searched companies to allCompanies
      //   ])
      // );
    },
    setRecommendationsData: (state, action) => {
      state.recommendationsData = action.payload;
    },
  },
});

export const {
  setRecentCompanies,
  setHotCompanies,
  setCategoryCompanies,
  setIndustries,
  setSearchData,
  addCompaniesByCategory,
  setPopularCompanies,
  setCompaniesReels,
  setSearchedCompaniesData,
  setRecommendationsData,
} = companiesSlice.actions;

export default companiesSlice.reducer;
